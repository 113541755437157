/* eslint-disable array-callback-return */
import React, { useState } from 'react';
import {
  Box,
  Container,
  Checkbox,
  Image,
  SimpleGrid,
  Flex,
} from '@chakra-ui/react';
import { Link, graphql } from 'gatsby';
import SEO from '../components/seo';
import Layout from '../components/layout';
import '../styles/case-study.scss';

const CaseStudies = (props) => {
  const { data } = props;
  const [postsList, setPostsList] = useState(data.allWpCaseStudy.edges);
  const [tags, setTags] = useState([]);
  const handleFilters = (event, tag) => {
    if (event.target.checked) {
      if (!tags.includes(tag)) {
        tags.push(tag);
      }
      setTags(tags);
    } else {
      const index = tags.indexOf(tag);
      tags.splice(index, 1);
      setTags(tags);
    }

    const filterResult = [];

    data.allWpCaseStudy.edges.filter((node) => {
      if (tags.length === 0) {
        filterResult.push(node);
      } else if (tags.length === 1) {
        const foundPost = node.node.caseCategories.nodes.find(
          (post) => post.slug === tags[0]
        );
        if (foundPost) {
          filterResult.push(node);
        }
      } else {
        const result = [];
        tags.forEach((subtag) => {
          const foundPost = node.node.caseCategories.nodes.find(
            (post) => post.slug === subtag
          );
          if (foundPost) {
            result.push(node);
          }
        });
        const toFindDuplicates = (a) =>
          a.filter((item, index) => a.indexOf(item) !== index);
        const duplicateElements = toFindDuplicates(result);
        if (duplicateElements.length !== 0) {
          duplicateElements.forEach((dup) => {
            if (dup.node.caseCategories.nodes.length >= tags.length) {
              filterResult.push(dup);
            }
          });
        }
      }
    });
    const uniqueResult = [...new Set(filterResult)];
    setPostsList(uniqueResult);
  };
  return (
    <Layout>
      <SEO
        title="Case Studies About Nutrient Solutions and Crystal Green Fertilzer | Ostara"
        description="Learn about the results of Crystal Green fertilizer and our nutrient recovery technology based on  real-world stories and data that demonstrate sustainability and ROI."
        keywords="Case Studies"
        image="https://pacemediafiles.s3.us-west-2.amazonaws.com/ostara/ostara_logo.png"
      />
      <main>
        <Box w="100%" p={4} className="case-study-body">
          <Container
            className="case-study-body--container"
            maxW={1400}
            marginTop={100}
          >
            <h1 className="case-study-body--heading">Case Studies</h1>
            <div className="case-study-filter-container">
              <p>
                Learn how our Crystal Green fertilizers and nutrient recovery
                technology impacts farms and communities with stories from
                researchers and customers.
              </p>

              <div className="case-study-filters">
                <h3>Filter by:</h3>
                {data.allWpCaseCategory.edges.map((tag) => (
                  <Checkbox
                    type="checkbox"
                    key={tag.node.slug}
                    className="custom-checkbox"
                    onChange={(event) => handleFilters(event, tag.node.slug)}
                  >
                    <span>✓</span>
                    {tag.node.name}
                  </Checkbox>
                ))}
              </div>
            </div>
          </Container>
          <Container maxW={1400}>
            <SimpleGrid columns={{ sm: 1, md: 2, lg: 3 }} spacing={6}>
              {postsList && postsList.length > 0
                ? postsList.map((item) => (
                    <Flex
                      flexDirection="column"
                      className="case-study--container"
                      key={item.node.title}
                    >
                      <Link to={`/case-studies/${item.node.slug}`}>
                        <Image
                          src={`${item.node.featuredImage.node.sourceUrl}`}
                          alt="Case Study img"
                          className="thumb"
                        />
                        <Image
                          src="/case-study/case-study-hover.png"
                          alt="Case Study img"
                          className="thumb-overlay"
                        />
                        <h5>{item.node.title}</h5>
                      </Link>

                      <div className="case-study-tags">
                        {item.node.caseCategories.nodes.map((tag) => (
                          <p key={tag.id}>{tag.name}</p>
                        ))}
                      </div>
                    </Flex>
                  ))
                : ''}
            </SimpleGrid>
          </Container>
        </Box>
      </main>
    </Layout>
  );
};

export default CaseStudies;
export const pageQuery = graphql`
  query {
    allWpCaseCategory {
      edges {
        node {
          name
          slug
        }
      }
    }
    allWpCaseStudy {
      edges {
        node {
          id
          slug
          title
          excerpt
          featuredImage {
            node {
              sourceUrl
            }
          }
          caseCategories {
            nodes {
              id
              name
              slug
            }
          }
        }
      }
    }
  }
`;
